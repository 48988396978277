/*
 * @Author: xiaoyaqu
 * @Date: 2019-08-19 13:42:58
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2023-07-11 11:39:59
 */

import {Toast} from 'vant';

import {genWebDeviceToken} from 'ssr-common/util/browser-util';
import initRealTimeLog from 'ssr-common/util/real-time-log';
import ua from 'ssr-common/util/webview/ua-parsed';
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';
import Vue from 'ssr-common/vue';
import interceptor from 'ssr-common/vue/mixin/interceptor';
import platformType from 'ssr-common/vue/mixin/platform-type';
import Vconsole from 'ssr-common/vue/mixin/vconsole';
import http from 'ssr-common/vue/vue-http';

import EcWebviewLoadingCircle from 'easycash/common/component/ec-webview-loading-circle';

import component from 'mabilis-cash/common/component';
import directive from 'mabilis-cash/common/directive';
import uploadEvent from 'mabilis-cash/common/mixin/upload-event';
import plugin from 'mabilis-cash/common/plugin';

const realTimeLog = initRealTimeLog('mabilis-cash', 'WEB_PHI_MAB', 'PHI');

const {common} = http.defaults.headers;
common['YQG-PLATFORM-SDK-TYPE'] = 'PHI_MAB';
common['YQG-PLATFORM-DEVICE-TOKEN'] = genWebDeviceToken();

if (ua.isWebview) {
    WebviewInterface.asyncCallHandler('getCurrentVersionCode').then(data => {
        common.build = data;
    });
}

Vue.use(component);
Vue.use(plugin);
Vue.use(directive);

export default {
    name: 'MabilisCash',

    components: {
        Toast,
        EcWebviewLoadingCircle
    },

    mixins: [Vconsole(Vue), uploadEvent, interceptor, platformType],

    created() {
        Vue.prototype.$app = this;
    },

    methods: {
        realTimeLog,
    }
};
